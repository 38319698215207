// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const firebaseConfig = {
  apiKey: 'AIzaSyBiM9ttpCKa7CNWDuRSy4axe7WCpiABFUE',
  authDomain: 'dzag-emdr-dev1.firebaseapp.com',
  databaseURL: 'https://dzag-emdr-dev1.firebaseio.com',
  projectId: 'dzag-emdr-dev1',
  storageBucket: 'dzag-emdr-dev1.appspot.com',
  messagingSenderId: '286421825410',
  appId: '1:286421825410:web:f4d9235e3ddc6b847d019b',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
