import { ElementRef, Injectable } from '@angular/core';
import * as contrast from 'contrast';
// @ts-ignore
import rgbHex from 'rgb-hex';

@Injectable()
export class ThemeService {

  private body$: HTMLBodyElement;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
  ) {
    this.body$ = this.elementRef.nativeElement.parentElement as HTMLBodyElement;
  }

  setBackgroundColor(color: string) {
    this.body$.style.setProperty('--body-color', color);
  }

  get isDarkBackground() {
    const color = getComputedStyle(this.body$).backgroundColor;
    const hex = rgbHex(color);
    return contrast(`#${hex}`) === 'dark';
  }

  get isLightBackground() {
    const color = getComputedStyle(this.body$).backgroundColor;
    const hex = rgbHex(color);
    return contrast(`#${hex}`) === 'light';
  }

}
