import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';

@NgModule({
  declarations: [RootComponent],
  imports: [BrowserModule, RootRoutingModule, CoreModule],
  providers: [],
  bootstrap: [RootComponent],
})
export class RootModule {}
